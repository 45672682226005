.faq-container {
    background: rgb(255, 255, 255);
    box-shadow: rgba(119, 119, 119, 0.14) 0px 16px 24px;
    border-radius: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 25px auto;
    padding: 35px 40px;

    .faq-header {
        font-size: 28px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        i {
            color: rgb(27, 185, 218);
            font-size: 32px;
            margin-right: 17px;
        }
    }

    .faq-item {
        margin-bottom: 30px;
        .faq-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 10px;
        }
        .faq-answer {
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.5px;
        }
    }
}
