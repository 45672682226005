.favorites-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
}

.favorite-btn {
    cursor: pointer;
    margin-left: 5px;
    width: 30px
}