.profile-container {
    .ok {
        color: #47c131 !important;
    }

    .danger {
        color: #ff0038 !important;
    }

    .meeting-container {
        background: #ffffff;
        border: 1px solid #d6d6d6;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 16px 15px;

        .meeting-name {
            text-align: left;
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            margin-left: 5px;
        }

        .meeting-desc {
            text-align: left;
            width: 100%;
            background: #f8f8f8;
            border-radius: 20px;
            font-size: 16px;
            line-height: 22px;
            padding: 12px 10px;
        }

        .meeting-users {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .profile-pic-container {
                text-align: center;
                color: #666;
                position: relative;
                height: 70px;
                margin: 5px 10px;
            }

            .profile-icon {
                font-size: 70px;
            }

            .profile-pic {
                width: 70px;
                border-radius: 50%;
            }
        }
    }

    .meeting-container-footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    @media screen and (max-width: 500px) {
        .profile-buttons > div {
            font-size: 16px !important;
        }
    }
}
