.user-container {
    //opacity: 0;

    .host .profile {
        border: 5px solid #1BB9DA;
    }

    .profile {
        border: 5px solid transparent;
    }


}

.gray-out {
    .profile {
        opacity: 0.5
    }
}

