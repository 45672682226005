* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    background: linear-gradient(360deg, #f9f9f9 18.55%, #f0f0f0 82%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#root {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

@import "./common/animations";
@import "./buttons/buttons";
@import "./components/components";
@import "./forms/forms";
@import "./icons/icons";
@import "./layout/layout";
@import "./common/modal";
@import "./tooltips/tooltips";
@import "./common/utils";
