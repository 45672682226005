.fade-in {
    animation-duration: 2s;
    animation-name: fadeIn;
    //animation-fill-mode: forwards;
}

.fade-out {
    animation-duration: 2s;
    animation-name: fadeOut;
}

@keyframes fadeIn {
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
     } 
  }

  @keyframes fadeOut {
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
     } 
  }


  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 1s ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 1s ease-in;
  }  