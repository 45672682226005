.start-meeting-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 100000;

    .modal-content {
        max-width: 518px;
        width: 100%;
        border-radius: 20px;
        background: #fff;
        padding: 40px 35px;
        margin: 50px auto;
        position: relative;
    }

    .modal-header {
        font-size: 22px;
        font-weight: bold;
        text-align: left;

        .close {
            cursor: pointer;
            color: #D6D6D6;
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }

    .modal-body {
        margin: 20px 0;

        input {
            width: 100%
        }

        .limit-text {
            font-size: 12px;
            color: #333;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px
        }

        .desc-text {
            margin-top: 20px;
            font-size: 14px;
            text-align: left;
        }
    }

    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .start-meeting-button {
        background: linear-gradient(30.73deg, #2F931D 16.02%, #47C131 84.3%);
        border-radius: 30px;
        color: #fff;
        font-weight: 800;
        font-size: 20px;
        padding: 10px 16px;
        cursor: pointer;
    }
}