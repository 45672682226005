.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 100000;

    .modal-content {
        max-width: 518px;
        width: 100%;
        border-radius: 20px;
        background: #fff;
        padding: 40px 35px;
        margin: 50px auto;
        position: relative;
    }

    .modal-header {
        font-size: 22px;
        font-weight: bold;
        text-align: left;

        .close {
            cursor: pointer;
            color: #D6D6D6;
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }

    .modal-body {
        margin: 20px 0;

        input {
            width: 100%
        }
    }

    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.text-center {
            justify-content: center;
        }
    }
}