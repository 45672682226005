.home-header-container {
  background: linear-gradient(339.37deg, #0ba2c0 15.68%, #1bb9da 84.64%);

  .header-container {
      background: transparent;
      color: #fff;
      height: 100px;

      .logo {
        height: 80px !important;
        transform: scale(1.5);
        
        @media screen and (max-width: 1024px) {
          transform: scale(1.2);
        }
      }

      .right-menu-mobile {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 100px;
        font-size: 14px;
        line-height: 19px;

        @media screen and (max-width: 1024px) {
            font-size: 20px;
            align-items: center;

            .btn-link {
              display: block; 
              margin: 10px 17px !important;
              width: 120px;
              text-align: center;
              font-size: 18px;
              text-transform: uppercase;
              font-weight: 900;
              height: 40px;
              line-height: 40px;
              border-radius: 40px;
              color: #1BB9DA !important;
              background: white;

              i {
                margin-right: 5px;
              }
            }

            span {display: none}
        }

        .btn-link {
          color: #fff;
          text-decoration: none;
          margin-right: 40px;
          cursor: pointer;
        }
      }
  }

  .btn-white {
    font-family: "Nunito";
    background: #fff;
    color: #1BB9DA;
    border-radius: 100px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 10px 24px;
    text-decoration: none;
    cursor: pointer;
    border: none;
  }

  .headline {
      text-align: center;
      color: #ffffff;
      padding: 10px 20px;

      @media (max-width: 1024px) {
        padding-bottom: 20px;
        padding-top: 0;
      }

      h1 {
          font-weight: 800;
          font-size: 48px;
          line-height: 64px;
          margin: 0;

          @media (max-width: 1024px) {
              font-size: 22px;
              line-height: 24px;
              font-weight: 700;
              text-transform: lowercase;
            
              &:first-letter{
                text-transform: capitalize;
              }
          }
      }

      p {
          margin: 10px 0 20px;
          font-weight: 300;
          font-size: 36px;
          line-height: 54px;

          @media (max-width: 1024px) {
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            margin: 5px 0 0;
            text-transform: lowercase;

            &:first-letter{
              text-transform: capitalize;
            }
          }
      }
  }
}

.header-container {
  position: relative;
  height: 100px;
  background: #fff;
  width: 100%;
  text-align: center;

  .header-icons-menu,
  .right-menu-search-icon,
  .header-line-2 {
    display: none;
  }

  .header-icons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0px;
    left: 10px;
    padding-top: 10px;
  }

  .logo {
    cursor: pointer;
    padding-top: 20px;
    height: 80px;
    user-select: none;
    margin-left: 10px;
  }

  .right-menu,
  .right-menu-mobile {
    position: absolute;
    top: 0;
    right: 40px;
    display: flex;
  }

  .primary-button {
    background: linear-gradient(30.73deg, #2f931d 16.02%, #47c131 84.3%);
    border-radius: 30px;
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    padding: 10px 16px;
    margin-top: 27px;
    cursor: pointer;
  }
}

.dropdown {
  width: 280px;
  padding: 10px;

  & > div:hover {
    background: #d2f1f8 !important;
  }

  .selected {
    font-size: 22px;
    color: #47c131;
    margin-right: 10px;
  }

  .danger {
    .selected {
      color: #ff0038;
    }
  }
}

.dropdown-item-icon {
  width: 50px;
  text-align: center;
  margin-right: 7px;
  margin-top: 5px;
}

.dropdown-item-name {
  font-size: 18px;
  font-weight: 800;

  &.ok {
    color: #47c131;
  }
  &.danger {
    color: #ff0038;
  }
}

@media only screen and (max-width: 900px) {
  .header-container {
    text-align: left;
    height: 120px;

    .logo {
      margin-left: 60px;
    }

    @media screen and (max-width: 374px) {
      height: 180px;
    }

    .logo {
      height: 60px;
    }

    .header-icons-menu {
      display: block;
      position: absolute;
      left: 20px;
      top: 25px;
      i {
        color: #1bb9da;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .header-icons {
      display: none;
    }

    .right-menu {
      display: none;
    }

    .right-menu-search-icon {
      display: block;
      position: absolute;
      right: 20px;
      top: 25px;
      i {
        color: #1bb9da;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .header-line-2 {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 374px) {
        flex-direction: column;
        justify-content: center;

        & > div {
          margin: 5px 0;
        }

        .status-btn {
          justify-content: center;
        }
      }

      .my-room-btn {
        .btn {
          margin-top: 0;
        }

        i {
          margin-left: 20px;
        }
      }

      .status-btn {
        & > div {
          display: inline-flex;
          align-items: center;
          border: 1px solid #d6d6d6;
          border-radius: 40px;
          padding-left: 18px;
          margin-right: 10px;
          cursor: pointer;

          height: 44px;

          font-weight: 800;
          font-size: 14px;
          line-height: 19px;

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          i {
            color: #d6d6d6;
            margin-left: 10px;
          }

          .profile-container {
            margin-left: 10px;
            width: 42px;
            height: 42px;

            .profile-pic {
              width: 42px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .header-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 100000;

    .menu {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      max-width: 300px;
      border-radius: 0 20px 20px 0;
      background: #fff;
      z-index: 10000;

      & > i {
        display: flex;
        justify-content: flex-end;
        margin: 20px 20px 0 20px;
        color: #666;
        font-size: 24px;
        cursor: pointer;
      }

      & > div {
        flex-direction: row !important;
        align-items: center;
        font-size: 20px !important;

        i {
          margin-right: 20px;
          width: 50px;
          text-align: center;
          font-size: 27px !important;
        }
      }
    }
  }
}
