.layout {
    text-align: center;
    margin-top: 20px;
}

.content-center {
    margin: 20px auto;
    max-width: 900px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 16px 24px rgba(119, 119, 119, 0.14);
    border-radius: 20px;
    text-align: left;
    padding: 10px 36px 30px;

    h1,h2,h3,h4,h5,h6 {
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .desktop-only {
        display: none !important;
    }
}

@media (min-width: 1025px) {
    .mobile-only {
        display: none !important;
    }
}

@import "./header";
@import "./search";