.my-team-container {
    display: flex;
    max-width: 1000px;
    width: 100%;
    margin: 20px auto;
    justify-content: center;
}

.my-team-user-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    width: 100%;
    margin: 20px auto;
    justify-content: center;
}