input,
select {
  font-family: "Nunito Sans";
  font-size: 16px;
  height: 40px;
  background: #f9f9f9;
  border-radius: 10px;
  border: none;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:active,
  &:focus {
    background: #ffffff;
    border: 1px solid #1bb9da;
    outline: none;
  }
}

input.error,
select.error {
  border: 1px solid #ff0038;
}

.checkbox {

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input {
    height: auto;
    margin-right: 5px;
  }
}

.form-group {
  margin-bottom: 10px;
  label {
    width: 100px;
    display: inline-block;
  }
  textarea {
    font-family: "Nunito Sans";
    background: #f9f9f9;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    box-sizing: border-box;
    border: 1px solid transparent;
    max-width: 400px;
    width: 100%;
  
    &:active,
    &:focus {
      background: #ffffff;
      border: 1px solid #1bb9da;
      outline: none;
    }
  }
}