.status-back-button {
    background: #ff0038;
    border-radius: 48px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    font-family: Nunito;
    color: #fff;
    cursor: pointer;
    padding: 10px 16px;
    margin-top: 27px;
    margin: 27px 0 0 10px;
}

.btn {
    background: #ff0038;
    border-radius: 48px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    font-family: Nunito;
    color: #fff;
    cursor: pointer;
    padding: 10px 16px;
    border: none;
    margin: 5px 10px;

    &.btn-ok {
        background: linear-gradient(30.73deg, #2F931D 16.02%, #47C131 84.3%);
    }

    &:disabled {
        background: #D6D6D6;
        cursor: not-allowed;
    }
}
