.search {
    margin: 20px auto;

    .search-container {
        position: relative;
    }

    .search-box {
        text-align: center;
        position: relative;
        width: 250px;
        margin: 0 auto;

        input {
            width: 100%
        }
    }

    .search-icon {
        position: absolute;
        top: 11px;
        left: 10px;
        color: #666;
    }

    .search-textbox {
        padding: 0 32px;
    }

    .search-close {
        position: absolute;
        top: 11px;
        right: 10px;
        color: #666;
        cursor: pointer;
    }
}