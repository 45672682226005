.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.text-ok {
    color: #47c131;
}

.text-danger {
    color: #ff0038;
    a {
        color: #ff0038
    }
}

.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    text-align: left;
}

.text-overflow-2-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-60 {
    margin-top: 60px;
}

.position-relative {
    position: relative;
}