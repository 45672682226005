.teams-separator {
    width: 100%;
    box-sizing: border-box;

    h2 {
        border-bottom: 1px solid #9f9f9f;
    }
}

h2 {
    margin: 30px 20px 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: #333333;

    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 40px;

        &:before,
        &:after {
            content: "";
            display: block;
            width: 1000px;
            position: absolute;
            top: 16px;
            border-top: 2px solid #D6D6D6;
        }

        &:before {
            right: 100%;
        }
        &:after {
            left: 100%;
        }
    }
}

.meetings-container {
    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}