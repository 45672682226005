.meeting-container {
}

.meeting-container-full {
    & > div {
        width: 100% !important;
        max-width: 900px !important;

        & > div {
            background-size: cover !important;
            background-repeat: no-repeat;
            background-color: transparent !important;
        }
    }

    .user-list {
        & > div {
            justify-content: center;
        }
    }
}

.meeting-header {
    position: relative;
    width: 100%;

    .private {
        font-size: 14px;
        line-height: 16px;
        border: 1px solid #ffffff;
        padding: 5px;
    }
}

.meeting-desc-container {
    position: relative;
    cursor: pointer;

    i {
        position: absolute;
        top: 35px;
        right: 10px;
    }

    input {
        border-radius: 15px;
        font-size: 16px;
        padding: 6px 23px;
        line-height: 36px;
        margin-top: 20px;
        width: 100%;
    }
}

.user-list {
    & > div {
        display: flex;
        flex-flow: row wrap;
    }
}
