.tooltip {
    position: relative;
    width: 100%;

    &.active:before {
        background: #333;
        opacity: 0.85;
        box-shadow: 0px 4px 8px rgba(82, 97, 115, 0.15);
        backdrop-filter: blur(2.71828px);
        top: -40px;
        left: calc(50% - 48px);
        border-radius: 3px;
        color: #fff;
        content: attr(title);
        position: absolute;
        padding: 6px 14px;
        width: 69px;
        z-index: 98;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
    }

    &.active:after {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba($color: #333, $alpha:  0.85);
        //opacity: 0.85;
        content: " ";
        position: absolute;
        top: -12px;
        left: calc(50% - 10px);
    }
}
