.home {
    background-color: white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .no-wrap {
        white-space: nowrap;
    }

    .banner img {
        width: 100%;
        height: auto;
        display: block;
    }

    .headline {
        background: linear-gradient(339.37deg, #0ba2c0 15.68%, #1bb9da 84.64%);
        text-align: center;
        color: #ffffff;
        padding: 34px 20px 16px 20px;

        h1 {
            font-weight: 800;
            font-size: 48px;
            line-height: 64px;
            margin-bottom: 0;

            @media (max-width: 1024px) {
                font-size: 45px;
                line-height: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 22px 0;
            font-weight: 300;
            font-size: 36px;
            line-height: 54px;
        }
    }

    .flex-box {
        color: #333333;
        background-color: white;
        width: 350px;
        margin: 60px 22px;

        @media (max-width: 1024px) {
            text-align: center;
            width: 75%;
            margin: 30px 0;
        }

        h2 {
            font-weight: 800;
            font-size: 36px;
            line-height: 49px;
            text-transform: none;
            text-align: left;
            margin: 0;

            @media (max-width: 1024px) {
                text-align: center;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 25px 0;
            padding-right: 28px;

            @media (max-width: 1024px) {
                padding-right: 0;
            }
        }
    }

    .grey-box {
        background: linear-gradient(358.87deg, #000000 0.49%, #333333 62.39%);
        width: 100%;

        img {
            max-width: 100%;
        }
    }

    .carousel-box {
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%), #666666;
        padding: 55px 0 40px 0;
        position: relative;

        h3 {
            font-weight: bold;
            font-size: 26.7097px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #ffffff;
            margin: 0 0 0px 0;

            @media (max-width: 1024px) {
                font-size: 24px;
                margin: -30px 0 20px 0;
            }
        }

        .carousel-container {
            margin: auto;
            max-width: 1440px;
            max-height: 605px;
            height: 60vw;

            @media (max-width: 1024px) {
                height: 50vw;
                margin-bottom: 50px;
            }
        }

        .carousel-nav {
            .prev-button,
            .next-button {
                position: absolute;
                top: 50%;
                z-index: 1;
                width: 30px;
                height: 30px;
                box-shadow: 0 0 12px 0 rgba(51, 51, 51, 0.3);
                background-color: #ffffff;
                border: none;
                font-size: 0;
                transition: opacity linear 200ms;
                opacity: 0;
                cursor: pointer;

                &:before,
                &:after {
                    content: "";
                    border: 6px solid transparent;
                    position: absolute;
                    left: 5px;
                    top: 9px;
                }

                @media (max-width: 1024px) {
                    opacity: 1;
                }
            }

            .prev-button {
                &:before {
                    border-right-color: #666;
                }

                &:after {
                    border-right-color: #fff;
                    left: 7px;
                }
            }

            .next-button {
                right: 0;

                &:before {
                    border-left-color: #666;
                    left: 13px;
                    top: 9px;
                }

                &:after {
                    border-left-color: #fff;
                    left: 10px;
                    top: 9px;
                }
            }

            ul,
            li {
                list-style-type: none;
            }

            ul {
                display: flex;
                margin: 0 auto;
                justify-content: center;
                padding: 0;
                position: absolute;
                z-index: 999;
                width: 100%;
                bottom: 40px;

                li {
                    background: rgba(255, 255, 255, 0.2);
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    font-size: 0;
                    margin: 0 5px;
                    cursor: pointer;

                    @media (max-width: 1024px) {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover,
                    &.active {
                        background: #ffffff;
                    }
                }
            }
        }

        &:hover {
            .prev-button,
            .next-button {
                opacity: 0.8;
            }
        }
    }

    .link-box {
        width: 100%;
        background: #fff;
        padding: 53px 10px 59px 10px;
        display: flex;
        justify-content: center;
        flex-direction: row;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 0;
            .badge-container {margin-bottom: 20px; margin-right:0 !important}
            .link-box-button {text-align: center; margin-bottom: 30px;}
        }

        .badge-container {
            margin-right: 84px;
            img {
                max-width: 224px;
            }
        }

        .text-container {
            height: 100%;
            background: #F7F7F7;
            border-radius: 30px;
            padding: 30px;
        }

        .white-box {
            width: 90%;
            max-width: 768px;
            background: #ffffff;
            box-shadow: 0px 8px 16px rgba(82, 97, 115, 0.18);
            border-radius: 12px;
            margin: 51px 0;
            padding: 50px 0 37px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .blue-box {
                width: 90%;
                max-width: 300px;
                background: #1bb9da;
                border-radius: 10px;
                text-align: center;
                padding: 24px 0 18px 0;
                margin-bottom: 23px;

                img {
                    max-width: 100%;
                }
            }
        }

        h3 {
            font-weight: bold;
            font-size: 32px;
            line-height: 44px;
            text-align: left;
            margin: 0 0 20px 0;
        }

        ol {
            padding-left: 0;
            margin-left: 16px;

            li {
                padding-bottom: 10px;
            }
        }

        .btn-link {
            font-family: "Nunito";
            display: inline-block;
            background: linear-gradient(270deg, #FF0038 0%, #FF2170 100%);
            color: #fff;
            border-radius: 100px;
            font-weight: 800;
            font-size: 22px;
            line-height: 30px;
            text-transform: uppercase;
            padding: 16px 32px;
            margin-top: 20px;
            text-decoration: none;
            cursor: pointer;
        }
    }

    footer {
        width: 100%;
        background: #333333;
        padding: 50px 0;
        display: none;
    }

    @media (max-width: 1024px) {
        .banner {
            order: 1;
        }
        .flex-box.visibility {
            order: 2
        }
        .grey-box {
            order: 3;
            background: none;
        }
        .flex-box.connection {
            order: 4;
        }
        .carousel-box {
            order: 5;
        }
        .flex-box.access {
            order: 6;
        }
        .link-box {
            order: 7;
        }
    }
}
